import React, { useEffect } from "react";
import { ThemeProvider, CssBaseline, Container } from "@mui/material";
import darkTheme from "./theme";
import { trackPageView } from "./analytics"; // Import the tracking function
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import IntroductionSection from "./components/IntroductionSection";
import FAQSection from "./components/FAQSection.tsx";
import "./fonts.css";
import "./index.css";
import { initializeGA } from "./analytics.js";

const App = () => {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initializeGA();
      window.GA_INITIALIZED = true;
    }
    // Track the page view when the component mounts
    trackPageView(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Home />
      <Container>
        <IntroductionSection />
        <Services />
        <FAQSection />
        <Contact />
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
